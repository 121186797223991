import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import TabDescription from '../components/TabDescription'
import SEO from '../components/SEO'

export const query = graphql`
  {
    mytab: contentfulTabDescription(tabname: { eq: "Bedankt" }) {
      description {
        childMarkdownRemark {
          html
        }
      }
      tabname
      title
    }
  }
`
const bedankt = ({ data: { mytab } }) => {
  return (
    <Layout>
      <SEO title='Bedankt' description='Bedankt voor je aanwezigheid' />
      <main className='page'>
        <section className='thankyou-page'>
          <article>
            <TabDescription {...mytab} />
          </article>
        </section>
      </main>
    </Layout>
  )
}

export default bedankt
